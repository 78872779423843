import tw from 'tailwind-styled-components'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NavBar, Footer, ErrorContainer } from '@foros-fe/core'

const Main = tw.main`flex flex-col h-screen`
const Container = tw.div`flex flex-col container flex-1`

export default function Custom404() {
  const { t } = useTranslation()

  return (
    <Main>
      <NavBar />
      <Container>
        <ErrorContainer title={t('errors.404.title')} message={t('errors.404.message')} />
      </Container>
      <Footer />
    </Main>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ssr: true,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  }
}
